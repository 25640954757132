import { graphql } from 'gatsby'
import React from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css" 
import Img from "gatsby-image" 

const RAgencementPage = ({ data }) => { 
 
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    dots:true,
    draggable:true, 
  };

  const g1 = data.g1.edges.map(({ node }) => node.childImageSharp)
  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
 
  return(
      <Layout>
        <SEO title="Agencement" />
        <div class="w-full relative">
            <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
                <h1 class="text-center text-2xl mt-5 font-bold">Nos Réalisations en Santé & Collectivité</h1>
                <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
            </div>
            <div className="slider-wrapper">
              <Slider  {...settingsMain}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div> 
        </div>  
      <div class="grid grid-cols-1 mt-8 md:mx-40 md:my-5 mx-3">
         <p class="mb-3 mt-10">
             Découvrez nos réalisations en Santé & Collectivité.
            Corsaf Design aménage avec le fabuleux Solid Surface tous vos locaux professionnels.
         </p>
        <p class="font-bold mb-4">Nous assurons la création de :</p>

         <ul class="list-disc pl-10">
            <li>Paillasse de laboratoire</li>
            <li>Paillasse médicale</li>
            <li>Plan Travail</li>
        </ul>
      </div> 
      <div class="w-full gl">
          <Gallery images={g1} />
          <Gallery images={g} />
      </div>
      </Layout> 
 )
}
export const query = graphql`
  query RSanteCollectivite {
    g1:allFile (filter:{relativeDirectory: {eq: "img/realisations/sante-collectivite/1"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    gallery:allFile (filter:{relativeDirectory: {eq: "img/realisations/sante-collectivite/r-g"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sld:allFile (filter:{relativeDirectory: {eq: "img/realisations/sante-collectivite/r-s"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default RAgencementPage